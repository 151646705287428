<template>
  <div class="zone container flex">
    <!--    <MapControlBlock class="zone__control"/>-->
    <div
      v-if="mapTransports"
      class="zone-block"
      :class="{ 'zone-block__all': infoBlockShow && zoneShowData && false }"
    >
      <MapBlock
        v-if="list && list.length"
        class="zone-map"
        :map-mark="list"
        :click-point="{ index: indexClick }"
        :selected-tracker-id="selectedTrackerId"
        :auto-width="infoBlockShow ? true : false"
        @click="infoBlockShow = $event"
        @noTrackerFound="handleNoTrackerFound"
      />
      <!--      <MapInfoBlock class="zone-map__info"/>-->
    </div>
    <div v-if="infoBlockShow && zoneShowData && false" class="zone__info main-block">
      <div class="main-block__head">
        <h2 class="main-block__title">Краткая информация</h2>
        <div class="zone-icon main-block__icon h3" @click="infoBlockShow = false">&times;</div>
        <!--        <img src="@/assets/png/select-icon.png" class="zone-icon main-block__icon" alt="" @click="infoBlockShow = false">-->
      </div>
      <ul v-if="zoneShowData" class="zone-info__items main-block__box">
        <!--        <li class="zone-info__item flex">-->
        <!--          <p class="zone-info__key">Текущий статус</p>-->
        <!--          <button class="table-btn__status">{{zoneShowData.source.status_listing_id}}</button>-->
        <!--        </li>-->
        <li class="zone-info__item flex">
          <p class="zone-info__key">Транспорт</p>
          <p class="zone-info__value">
            {{ zoneShowData.label }}
          </p>
        </li>
        <li class="zone-info__item flex">
          <p class="zone-info__key">Номер машины</p>
          <p class="zone-info__value" />
        </li>
        <li class="zone-info__item flex">
          <p class="zone-info__key">Вид</p>
          <p class="zone-info__value">
            {{ zoneShowData.source.model }}
          </p>
        </li>
        <li class="zone-info__item flex">
          <p class="zone-info__key">Год выпуска</p>
          <p class="zone-info__value">
            {{ zoneShowData.source.creation_date.split("-")[0] }}
          </p>
        </li>
        <li class="zone-info__item flex">
          <p class="zone-info__key">Температурный режим</p>
          <p class="zone-info__value">{{ trackerId.temp >= 300 ? trackerId.temp : 0 }} °С</p>
        </li>
        <li class="zone-info__item flex">
          <p class="zone-info__key">
            КРВП
            <b-icon icon="info-circle" font-scale="1" style="color: #acacac" />
          </p>
          <p class="zone-info__value" />
        </li>
      </ul>
    </div>
    <div class="zone__info main-block">
      <div v-if="noTrackerFound" class="zone__info-no-tracker-found">Трэкер не найден</div>
      <div class="main-block__head">
        <h2 class="main-block__title">Список TC</h2>
        <div class="flex">
          <p class="mr-2">Cвободные</p>
          <b-form-checkbox v-model="freeShow" class="city-add__checkbox" />
        </div>
        <!--        <img src="@/assets/png/select-icon.png" class="zone-icon main-block__icon" alt="">-->
      </div>
      <ul v-if="transportList" class="zone-info__items main-block__box">
        <li
          v-for="(item, index) in transportList"
          :key="index"
          :class="{ 'zone-info__item-active': activeItem === index }"
          class="zone-info__item"
          @click="pointTransport(index, transportList[index].tracker_id), (activeItem = index)"
        >
          <div class="flex">
            <p class="zone-info__value">
              {{ item.model ? item.model.name : "" }}
            </p>
            <p class="zone-info__value">
              {{ item.model ? item.number : "" }}
            </p>
          </div>
          <div class="flex">
            <p class="zone-info__key">
              {{ item.status === "free" ? "Свободен" : "В пути" }}
            </p>
            <!--            {{ item.tracker_id }}-->
            <span v-if="item.tracked" class="table-btn__status" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    MapBlock: () => ({
      component: import("@/components/map/map3.vue"),
    }),
    // MapInfoBlock: () => ({
    //   component: import('@/components/map/map-info.vue')
    // }),
    // MapControlBlock: () => ({
    //   component: import('@/components/map/map-control.vue')
    // })
  },
  data() {
    return {
      indexClick: null,
      selectedTrackerId: null,
      infoBlockShow: false,
      freeShow: false,
      activeItem: null,
      noTrackerFound: false,
    };
  },
  computed: {
    ...mapState(["mapTransports", "transportList", "zoneShowData", "trackerId"]),
    list() {
      if (!this.mapTransports) {
        return [];
      } else {
        return this.mapTransports.map((e) => [e.coordination?.lat, e.coordination?.lng]);
      }
    },
    filterTransportList() {
      let productFilter = [...this.transportList];
      if (this.freeShow) {
        productFilter = productFilter.filter((i) => i.status === "free");
      }
      return productFilter;
    },
  },
  watch: {
    zoneShowData() {
      this.trackerProduct(this.zoneShowData.id);
    },
  },
  async created() {
    this.$store.dispatch("getMapTransports");
    await this.$store.dispatch("getTransportList");
  },
  async mounted() {
    await this.$store.dispatch("getMapTransports");
  },
  methods: {
    showNotFound() {
      this.noTrackerFound = true;
      setTimeout(() => {
        this.noTrackerFound = false;
      }, 3000);
    },
    handleNoTrackerFound() {
      this.showNotFound();
    },
    pointTransport(value, trackerId) {
      this.indexClick = value;
      this.selectedTrackerId = trackerId;
      if (!this.selectedTrackerId) this.showNotFound();
      this.infoBlockShow = true;
    },
    async trackerProduct(id) {
      await this.$store.dispatch("getTrackerId", {
        tracker_id: id,
      });
    },
  },
};
</script>

<style scoped>
.zone__info-no-tracker-found {
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: var(--danger);
  color: white;
}
.zone {
  height: calc(100vh - 100px);
  position: relative;
  align-items: flex-start;
  margin-top: 20px;
}
.zone-block {
  width: 75%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.zone-block__all {
  width: 45%;
}
.zone__control {
  position: absolute;
  top: 10px;
  left: 25px;
  z-index: 1;
}
.zone-map__info {
  position: absolute;
  top: 20%;
  left: 35%;
}
.spinner {
  top: 45%;
  left: 35%;
  z-index: -1;
}
.zone-icon {
  transform: rotate(270deg);
  cursor: pointer;
  font-size: 24px;
}
.zone__info {
  width: 25%;
  margin-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.zone-info__items {
  height: 100%;
  max-height: 100%;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}
.zone-info__item {
  /*height: 39px;*/
  margin: 5px 0;
  border-radius: 5px;
  padding: 11.5px 5px 5px 5px;
  cursor: pointer;
}
.zone-info__item:hover {
  background-color: #e9ecf6;
}
.zone-info__item:not(:last-child) {
  padding-bottom: 11.5px;
  /*height: 39px;*/
}
.zone-info__item-active {
  background-color: #e0e9fa;
}
.zone-info__value {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}
.zone-info__key {
  text-align: start;
}
.table-btn__status {
  background: var(--secondary-color);
  line-height: 14px;
  color: #ffffff;
  margin: 0;
}
</style>
